
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import { KEY } from '@/inversify.keys';
import type UserService from '@/modules/user/user.service';

@Component({ components: { PageWrapper } })
export default class SettingsPage extends Vue {
    @inject(KEY.UserService) userService!: UserService;
}
